const baseUrl = "https://api.telegram.org/bot7434798755:AAH-vm5FeAkELnrgSQVckUfvebsFwYdhn2c/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-4571433556&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};
